<template>
  <v-container
    :class="loading ? 'progressheight' : ''"
    fluid
    grid-list-md
    text-xs-center
  >
    <v-row class="fill-height" align-content="center" justify="center">
      <v-col cols="6">
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          color="blue-grey accent-4"
          rounded
          height="6"
        />
      </v-col>
    </v-row>
    <v-layout v-if="!loading" row wrap>
      <v-flex lg12 xl12>
        <v-layout row>
          <v-flex lg10 xl10>
            <v-layout>
              <v-flex lg2 xl2>
                <v-card class="card-header pa-3">
                  Atualizado em:
                  <div>{{ getUpdateTime | formatDate }} </div>
                  <div>{{ getUpdateTime | formatTime }}</div>
                </v-card>
              </v-flex>
              <v-flex lg10 xl10>
                <v-card
                  class="card-header d-flex justify-md-space-between justify-lg-space-between"
                >
                  <div class="intervalo">
                    <v-card-title>
                      Intervalo:
                    </v-card-title>
                    <div class="comboInterval">
                      <div>
                        <div class="label">Início</div>
                        <div
                          >{{ setIntervalo.start | formatDate }}
                          <span>
                            {{ setIntervalo.start | formatTimeHm }}
                          </span></div
                        >
                        <!-- <div>{{setIntervalo.start | formatTimeHm}}</div> -->
                      </div>
                      <div>
                        <div class="label">Fim</div>
                        <div>
                          {{ setIntervalo.end | formatDate }}
                          <span>{{ setIntervalo.end | formatTimeHm }}</span>
                        </div>
                        <!-- <div>{{setIntervalo.end | formatTimeHm}}</div> -->
                      </div>
                    </div>
                  </div>

                  <div>
                    <v-tooltip color="info" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="reportDialogVisible = true"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="#FFFFFF !important">
                            {{ mdiFileExport }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Definir Intervalo</span>
                    </v-tooltip>
                    <v-tooltip color="info" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="downloadReport"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="#FFFFFF !important">
                            {{ mdiDownload }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Download de Relatório</span>
                    </v-tooltip>
                  </div>
                  <Dialog
                    v-on:activelineChart="updateLinecharts"
                    v-if="activeSite"
                    v-model="reportDialogVisible"
                    width="620"
                  >
                  </Dialog>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex lg2 xl2>
            <v-card class="card-header d-flex">
              <div>
                Alertas
              </div>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex lg10 xl10>
            <v-layout ref="infoBox" row wrap>
              <v-flex lg2 xl2>
                <BarCard
                  ref="barone"
                  :series="Barchart.tension"
                  Title="Tensão"
                  type="bar"
                  Id="barcharttension"
                  :categorie="tensionCategorie"
                />
              </v-flex>
              <v-flex lg8 xl8>
                <!-- <v-card class="pt-4" color="primary"> -->
                <CurrentChartCard
                  :configy="tensao.config"
                  :series="tention"
                  id="linechartTensao"
                />
                <!-- </v-card> -->
              </v-flex>
              <v-flex xl2>
                <v-card class="pa-2" light color="primary">
                  <div class="d-flex align-center" style="min-height: 245px">
                    <!-- <Media /> -->
                    <div v-if="media !== null" class="col-12 card3">
                      <div class="row d-flex justify-center align-center">
                        <div class="col-6 pa-0">Bat 1</div>
                        <div v-if="media.meanV" class="col-6 labelValue">
                          <span>{{ media.meanV.bat1 }}</span>
                        </div>
                      </div>
                      <div class="row d-flex justify-center align-center">
                        <div class="col-6 pa-0">Bat 2</div>
                        <div class="col-6 labelValue">
                          <span>{{ media.meanV.bat2 }}</span>
                        </div>
                      </div>
                      <div class="row d-flex justify-center align-center">
                        <div class="col-6 pa-0">Bat 3</div>
                        <div class="col-6 labelValue">
                          <span>{{ media.meanV.bat3 }}</span>
                        </div>
                      </div>
                      <div class="row d-flex justify-center align-center">
                        <div class="col-6 pa-0">Bat 4</div>
                        <div class="col-6 labelValue">
                          <span>{{ media.meanV.bat4 }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout row wrap align-center>
              <v-flex lg2 xl2>
                <v-card class="p-4" light color="primary">
                  <GaugeChart title="Corrente" :value="getgaugeChart" />
                </v-card>
              </v-flex>
              <v-flex lg8 xl8>
                <CurrentChartCard
                  :configy="corrent.config"
                  :series="corrent.dataCurrent"
                  id="linechartCorrente"
                />
              </v-flex>
              <v-flex lg2 xl2>
                <v-card class="pa-2" light color="primary">
                  <div
                    v-if="media !== null"
                    class="d-flex align-center"
                    style="min-height: 245px"
                  >
                    <!-- <Media /> -->
                    <div class="col-12 card3">
                      <div class="row d-flex justify-center align-center">
                        <div class="col-6 pa-0">Carga</div>
                        <div class="col-6 labelValue">
                          <span>{{ media.meanc.ahC }}</span>
                        </div>
                      </div>
                      <div class="row d-flex justify-center align-center">
                        <div class="col-6 pa-0">Descarga</div>
                        <div class="col-6 labelValue">
                          <span>{{ media.meanc.ahD }}</span>
                        </div>
                      </div>
                      <div class="row d-flex justify-center align-center">
                        <div class="col-6 pa-0">Balanço</div>
                        <div class="col-6 labelValue">
                          <span>{{ media.meanc.ah }}</span>
                        </div>
                      </div>
                      <div class="row d-flex justify-center align-center">
                        <div class="col-6 pa-0">Des/Car</div>
                        <div class="col-6 labelValue">
                          <span>{{ media.meanc.ahP }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex lg2 xl2>
                <BarCard
                  ref="barcard"
                  :series="Barchart.temperatura"
                  Title="Temperatura"
                  type="bar"
                  Id="bar-chart-temperature"
                  :categorie="tempCategorie"
                />
              </v-flex>
              <v-flex lg8 xl8>
                <!-- <v-card class="pt-4" light color="primary"> -->
                <CurrentChartCard
                  :configy="temperatura.config"
                  :series="temperatura.dataTemp"
                  id="linechartTemperatura"
                  lab="'h'"
                />
                <!-- </v-card> -->
              </v-flex>
              <v-flex lg2 xl2>
                <v-card class="pa-2" light color="primary">
                  <div
                    v-if="media !== null"
                    class="d-flex justify-center flex-column"
                    style="min-height: 245px"
                  >
                    <!-- <Media /> -->
                    <div class="col-12 card3">
                      <div class="row d-flex justify-center align-center">
                        <div class="col-6 pa-0">T1</div>
                        <div class="col-6 labelValue">
                          <span>{{ media.meanT.tmp1 }}</span>
                        </div>
                      </div>
                      <div class="row d-flex justify-center align-center">
                        <div class="col-6 pa-0">T2</div>
                        <div class="col-6 labelValue">
                          <span>{{ media.meanT.tmp2 }}</span>
                        </div>
                      </div>
                      <div class="row d-flex justify-center align-center">
                        <div class="col-6 pa-0">T3</div>
                        <div class="col-6 labelValue">
                          <span>{{ media.meanT.tmp3 }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex lg2 xl2>
            <AlertsTable :alerts="alerta" :matchHeight="matchHeight" />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// import axios from "axios";
import axios from "@/config/axios";

import { mapGetters, mapActions } from "vuex";
import { mdiChevronLeft, mdiFileExport, mdiDownload } from "@mdi/js";
import AlertsTable from "@/components/AlertsTable";
import CurrentChartCard from "./parts/CurrentChartCard";
// import Media from "../Overview/parts/Media.vue";

import BarCard from "./charts/bar";
import GaugeChart from "./charts/chartgauge";
import Dialog from "../../components/datarange.vue";

import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export default {
  name: "SiteDashboard",
  components: {
    BarCard,
    GaugeChart,
    AlertsTable,
    CurrentChartCard,
    Dialog,
    // Media,
  },
  data() {
    return {
      tensionCategorie: ["BAT1", "BAT2", "BAT3", "BAT4"],
      tempCategorie: ["Temp1", "Temp2", "Temp3"],
      loading: false,
      mdiChevronLeft,
      mdiDownload,
      reportDialogVisible: false,
      mdiFileExport,
      pollingTimer: null,
      pollingTimerLine: null,
      loadingActive: false,
      matchHeight: null,
      alerts: null,
      // media: null,
      labelDate: [],
      configTens: 0,
      configCurrent: 0,
      configTemp: 0,
      barChartTensionArray: [],
      chartGaugeCorrent: {
        data: 0,
        config: {},
      },
      barChartTemperatureArray: [],

      TesnsaoSeries: [
        {
          name: "Tensão",
          data: [],
        },
      ],

      dataTensao: [
        {
          name: "",
          data: [],
        },
        {
          name: "teste",
          data: [[1636589967000, 17.52]],
        },
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
      ],

      dataCurrent: [
        {
          data: [],
          name: "Corrente",
        },
      ],

      dataTemp: [
        {
          data: [],
          name: "Temp 1",
        },
        {
          data: [],
          name: "Temp 2",
        },
        {
          data: [],
          name: "Temp 3",
        },
      ],

      TemperaturaSeries: [
        {
          name: "Temperatura",
          data: [],
        },
      ],

      tensionLineData: [],
    };
  },
  computed: {
    ...mapGetters([
      "config",
      "theme",
      "activeSite",
      "media",
      "corrent",
      "tensao",
      "temperatura",
      "getUpdateTime",
      "Barchart",
      "getgaugeChart",
      "getLinecharts",
      "getIntervalo",
    ]),
    siteId() {
      return parseInt(this.$route.params.siteId, 10);
    },
    tention() {
      return this.tensao.dataTensao;
    },

    alerta() {
      return this.Barchart.alarm;
    },
    setIntervalo() {
      return this.getIntervalo ? this.getIntervalo : "";
    },

    chartGaugeCorrentData() {
      return this.chartGaugeCorrent.data;
    },
  },

  async created() {
    try {
      if (this.activeSite === null) {
        let _this = this;

        await this.$nextTick(() => {
          _this.loadAndSetActiveSite(_this.siteId);
          // this.$store.dispatch('loadAndSetActiveSite', this.siteId)
        });
      }
      this.getLeftCharts();
      this.getLineCharts();
      this.pollingTimer = setInterval(this.updateLeftCharts, 30000);
      // this.pollingTimerLine = setInterval(this.getLineCharts, 30000);
    } catch (error) {
      alert("Ocorreu um erro ao carregar dados");
    }
  },

  destroyed() {
    clearInterval(this.pollingTimer);
    clearInterval(this.pollingTimerLine);
    this.$store.dispatch("clearActiveSite");
  },

  methods: {
    ...mapActions([
      "loadAndSetActiveSite",
      "setUpdateTime",
      "loadlinechart",
      "loadbarchart",
    ]),
    updateLinecharts() {
      this.updateTensaoChart();
      this.updateTempChart();
      this.updateCurrentChart();
    },
    updateTensaoChart() {
      this.$nextTick(() => {
        this.$apexcharts.exec(
          "linechartTensao",
          "updateSeries",
          [
            {
              data: this.tensao.dataTensao[0].data,
            },
            {
              data: this.tensao.dataTensao[1].data,
            },
            {
              data: this.tensao.dataTensao[2].data,
            },
            {
              data: this.tensao.dataTensao[3].data,
            },
          ],
          true
        );
      });
    },
    updateTempChart() {
      if (this.dataTemp.length > 0) {
        this.$nextTick(() => {
          this.$apexcharts.exec(
            "linechartTemperatura",
            "updateSeries",
            [
              {
                data: this.temperatura.dataTemp[0].data,
              },
              {
                data: this.temperatura.dataTemp[1].data,
              },
              {
                data: this.temperatura.dataTemp[2].data,
              },
            ],
            true
          );
        });
      }
    },
    updateCurrentChart() {
      if (this.dataCurrent.length > 0) {
        // let currentData = res;
        this.$nextTick(() => {
          this.$apexcharts.exec(
            "linechartCorrente",
            "updateSeries",
            [
              {
                data: this.corrent.dataCurrent[0].data,
              },
            ],
            true
          );
        });
      }
    },

    async updateLeftCharts() {
      try {
        await this.$store.dispatch("loadbarchart", { _id: this.siteId });

        this.$apexcharts.exec(
          "barcharttension",
          "updateSeries",
          [
            {
              data: this.Barchart.tension[0].data,
            },
          ],
          true
        );
        this.$apexcharts.exec(
          "bar-chart-temperature",
          "updateSeries",
          [
            {
              data: this.Barchart.temperatura[0].data,
            },
          ],
          true
        );
      } catch (error) {
        console.log(error);
      }
    },

    async getLeftCharts() {
      this.loadingActive = true;
      try {
        await this.$store.dispatch("loadbarchart", { _id: this.siteId });
      } catch (error) {
        console.log(error);
      }
      // await axios
      //   .get("/devcommnew", {
      //     params: { _id: this.siteId },
      //   })
      //   .then((res) => {
      //     this.alerts = res.data.alarm;

      //     this.resolved(res);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },

    //get data Line charts
    async getLineCharts(obj) {
      dayjs.extend(utc);
      dayjs.extend(timezone);
      let startDate = dayjs(new Date()).format("YYYY-MM-DD");
      let EndtDate = dayjs(new Date()).format("YYYY-MM-DD");
      let EndTime = dayjs(new Date()).format("HH:mm:ss");
      let start = dayjs(`${startDate} 00:00:00`)
        .tz("America/Sao_Paulo", true)
        .unix();
      let end = dayjs(`${EndtDate} ${EndTime}`)
        .tz("America/Sao_Paulo", true)
        .unix();

      const param = obj
        ? obj
        : {
            start: start, // hoje 0h
            end: end, // hoje agora
            id: this.siteId,
          };
      try {
        // if(this.getLinecharts == null) {
        this.$store.commit("Intervalo", param);
        await this.loadlinechart(param);
        this.loading = false;
        // }
        // await this.resolvedLineChart(this.getLinecharts);
      } catch (error) {
        console.log(error);
      }
      // await axios
      //   .get("/linechartnew", {
      //     params: { _id: this.siteId },
      //   })

      //   .then((res) => {
      //     this.resolvedLineChart(res.data);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },

    async refreshSite() {
      let _this = this;
      console.log("loadAndSetActiveSite", this.siteId);
      if (_this.activeSite === null) {
        _this.loadAndSetActiveSite(_this.siteId);
      }
    },

    resolved(res) {
      let arr = [];
      this.chartGaugeCorrent.data = res.data.cur / 10;
      this.$set(arr, 0, res.data.v1 / 100);
      this.$set(arr, 1, res.data.v2 / 100);
      this.$set(arr, 2, res.data.v3 / 100);
      this.$set(arr, 3, res.data.v4 / 100);
      this.barChartTensionArray = arr;

      this.$set(this.TesnsaoSeries[0], "data", this.barChartTensionArray);

      this.barChartTemperatureArray[0] = res.data.t1;
      this.barChartTemperatureArray[1] = res.data.t2;
      this.barChartTemperatureArray[2] = res.data.t3;

      this.$set(
        this.TemperaturaSeries[0],
        "data",
        this.barChartTemperatureArray
      );

      this.setUpdateTime(res.data.updateTime);
      if (this.barChartTensionArray.length > 0) {
        this.$nextTick(() => {
          this.updateLeftCharts();
        });
      }
      this.loadingActive = false;
    },

    resolvedLineChart(res) {
      // this.getMedia(res)
      // this.getcorrenteData(res.Xaxis, [res.datavc], res);

      this.gettensaoData(
        res.Xaxis,
        [res.datav1, res.datav2, res.datav3, res.datav4],
        res
      );

      this.gettemperaturaData(
        res.Xaxis,
        [res.datat1, res.datat2, res.datat3],
        res
      );
    },
    getMedia(res) {
      console.log(res);
      this.media = {
        meanc: res.meanC,
        meanT: res.meanT,
        meanV: res.meanV,
      };
    },
    gettensaoData(data, res, config) {
      this.configTens = {
        min: config.configs.configVmin,
        max: config.configs.configVmax,
      };
      let test = null;
      res.forEach((tensao, index) => {
        if (tensao) {
          this.$set(this.dataTensao[index], "data", []);
          test = tensao.data.map((el, i) => {
            return [data.data[i] * 1000, el];
          });
          this.$set(this.dataTensao[index], "data", test);
          this.$set(this.dataTensao[index], "name", res[index].name);
        }
      });
      console.log(test);
      // let label0 = res[0].data.map((el, i) => {
      //   return [data.data[i] * 1000, el];
      // });
      // let label1 = res[1] ? res[1].data.map((el, i) => {
      //   return [data.data[i] * 1000, el];
      // }) : '';
      // let label2 = res[2]? res[2].data.map((el, i) => {
      //   return [data.data[i] * 1000, el];
      // }) : '';
      // let label3 = res[3]? res[3].data.map((el, i) => {
      //   return [data.data[i] * 1000, el];
      // }): '';

      // this.$set(this.dataTensao[0], "data", label0);
      // this.$set(this.dataTensao[1], "data", label1);
      // this.$set(this.dataTensao[2], "data", label2);
      // this.$set(this.dataTensao[3], "data", label3);

      this.tensionLineData = res;
      this.updateLinheChart();
      // if (this.dataTensao.length > 0) {
      // }
      this.loading = false;
    },
    getcorrenteData(data, res, config) {
      this.configCurrent = {
        min: config.configs.configCmin,
        max: config.configs.configCmax,
      };
      let label = res[0].data.map((el, i) => {
        return [data.data[i] * 1000, el];
      });
      this.$set(this.dataCurrent[0], "data", label);

      if (this.dataCurrent.length > 0) {
        // let currentData = res;
        this.$nextTick(() => {
          this.$apexcharts.exec(
            "linechartCorrente",
            "updateSeries",
            [
              {
                data: this.dataCurrent[0].data,
              },
            ],
            true
          );
        });
      }
    },
    gettemperaturaData(data, res, config) {
      this.configTemp = {
        min: config.configs.configTmin,
        max: config.configs.configTmax,
      };

      let test = null;
      res.forEach((tempe, index) => {
        if (tempe) {
          this.$set(this.dataTemp[index], "data", []);
          test = tempe.data.map((el, i) => {
            return [data.data[i] * 1000, el];
          });
          this.$set(this.dataTemp[index], "data", test);
          this.$set(this.dataTemp[index], "name", res[index].name);
        }
      });

      // let label1 = res[0].data.map((el, i) => {
      //   return [data.data[i] * 1000, el];
      // });
      // let label2 = res[1].data.map((el, i) => {
      //   return [data.data[i] * 1000, el];
      // });
      // let label3 = res[2].data.map((el, i) => {
      //   return [data.data[i] * 1000, el];
      // });

      // this.$set(this.dataTemp[0], "data", label1);
      // this.$set(this.dataTemp[1], "data", label2);
      // this.$set(this.dataTemp[2], "data", label3);

      if (this.dataTemp.length > 0) {
        this.$nextTick(() => {
          this.$apexchaecrts.exec(
            "linechartTemperatura",
            "updateSeries",
            [
              {
                data: this.dataTemp[0].data,
              },
              {
                data: this.dataTemp[1].data,
              },
              {
                data: this.dataTemp[2].data,
              },
            ],
            true
          );
        });
      }
    },

    downloadReport() {
      let host = process.env.VUE_APP_API_URL;
      var url = `${host}rel?${"id=" +
        this.siteId +
        "&t1=" +
        this.getIntervalo.start +
        "&t2=" +
        this.getIntervalo.end}`;
      let link = window.document.createElement("a");
      link.href = url;
      this.loading = true;
      axios
        .get(url)
        .then(res => {
          if (res.status == 200) {
            this.loading = false;
            link.click();
          }
        })
        .catch(err => {
          this.loading = false;
          console.error(err);
        });

      // window.open(url)
      this.dates = {};
      this.$emit("toggle", false);
    },
  },

  mounted() {
    // this.refreshSite();
    this.loading = true;

    this.matchHeight = (this.$refs.infoBox.clientHeight - 3) * 3;
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/mixins";
#inspire .v-application--wrap {
  min-height: calc(100vh - 65px) !important;
}
.progressheight {
  height: 66vh;
}
.labelValue {
  width: 40%;
  padding: 0;
  background-color: #3a567d;
  height: 2.1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-right-style: solid;
  border-left-style: solid;
  border-right-color: #cccc;
  border-left-color: #cccc;
  span {
    color: #fff;
  }
}
.legend {
  color: rgb(55, 61, 63);
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
}
.site-dashboard {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 100%;
  grid-template-rows: repeat(8, auto);
  grid-template-areas:
    "."
    "batteries"
    "voltageChart"
    "current"
    "currentChart"
    "temperatures"
    "temperatureChart"
    "events";

  @include md-and-up {
    height: calc(100vh - 64px);
    grid-template-columns: 400px auto 280px;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      "batteries      voltageChart       events"
      "current    currentChart       events"
      "temperatures   temperatureChart   events";
  }
  .v-progress-circular {
    margin: 1rem;
  }
}
.voltageChart {
  grid-area: voltageChart;
}
.currentChart {
  grid-area: currentChart;
}
.temperatureChart {
  grid-area: temperatureChart;
}
.batteries {
  grid-area: batteries;
}
.current {
  grid-area: current;
}
.temperatures {
  grid-area: temperatures;
}
.events {
  grid-area: events;
}
.card-header {
  background-color: map-get($material-dark, "secondary-light");
  color: #fff;
  padding: 1rem;
  min-height: 90px;
  max-height: 90px;
  align-items: center;
}
.intervalo {
  display: flex;
  gap: 15px;
  align-items: center;
  .comboInterval {
    display: flex;
    gap: 22px;
    .label {
      font-size: 12px;
    }
    span {
      font-size: 13px;
    }
  }
}

@include md-and-up {
  .v-window-item {
    height: 100%;
  }
}

.card3 {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
</style>
