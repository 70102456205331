<template>
<div>
    <v-card class="pt-4" color="primary">
    <div class="loading" v-if="loading">
      <v-progress-circular
        indeterminate
        color="secondary"
      ></v-progress-circular>
    </div>
      <bar-chart v-else
      :type="type"  :options="TensaoOptions" :series="series"
      />
    </v-card>

</div>
</template>

<script>

import BarChart from '../charts/chart'

export default {
  props: {
    loading: {
      type:Boolean,
      default: false
    },
    Options: {
      type: Object,
    },
    type:{
      type: String
    },
    series:{
      type: Array
    },
    Title:{
      type: String
    },
    Id:{
      type: String
    },
    categorie: {
      type: Array
    }
  },
  components: {
    BarChart,
  },
  data() {
    return {
      TensaoOptions: {
          chart: {
            id: this.Id,
            height: 230,
            toolbar: {
              show: false,
            },
            animations: {
              enabled: false,
              dynamicAnimation: {
                enabled: false,
              },
            },
          },
          plotOptions: {
            bar: {
              // borderRadius: 10,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val ? val : '';
            },
            offsetY: -30,
            style: {
              fontSize: "12px",
              colors: ["#304758"],
            },
          },
          grid: {
            show: false,
          },
          xaxis: {
            categories: this.categorie,
            position: "top",
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
            },
            crosshairs: {
              fill: {
                type: "gradient",
                gradient: {
                  colorFrom: "#D8E3F0",
                  colorTo: "#BED1E6",
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                },
              },
            },
            tooltip: {
              enabled: false,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val;
              },
            },
          },
          title: {
            text: this.Title,
            align: "left",
            margin: 20,
            style: {
              fontSize: "20px",
              fontWeight: "bold",
            },
          },
          tooltip: {
            enabled: true,
            theme: "light",
          },
          responsive: [
            {
              breakpoint: 600,
              options: {
                yaxis: {
                  show: false,
                },
                legend: {
                  show: false,
                },
              },
            },
          ],
          legend: {
            show: false
          }
      }
    }
  },
  methods: {
    init() {
    }
  },
  mounted () {
    this.$apexcharts.exec(
        "barcharttension",
        "updateOptions",
        {
          yaxis:{
          labels: {
              show: false,
              formatter: function (val) {
                return val + ' V';
              },
          },
        }
        },
        true
      );
    this.$apexcharts.exec(
        "bar-chart-temperature",
        "updateOptions",
        {
          yaxis:{
          labels:{
            show:false,
            formatter: (val) => {return val + ' º'}
          }
        }
        },
        true
      );
  },


}
</script>

<style scoped>
.loading {
  min-height: 245px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
