var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"620","content-class":"report-dialog","value":_vm.visible},on:{"input":function($event){return _vm.$emit('toggle', $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",null,{"on":on,"attrs":attrs})]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticClass:"headline text--secondary",class:this.$vuetify.theme.dark ? 'primary' : 'secondary'},[_vm._v(" Intervalo ")]),_c('v-card-text',{staticClass:"report-text"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data Inicial","persistent-hint":"","prepend-icon":"mdi-calendar"},model:{value:(_vm.DateStartFormatted),callback:function ($$v) {_vm.DateStartFormatted=$$v},expression:"DateStartFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"color":"info","no-title":"","max":_vm.nowDate},model:{value:(_vm.dates.start),callback:function ($$v) {_vm.$set(_vm.dates, "start", $$v)},expression:"dates.start"}},[_c('v-text-field',{attrs:{"type":"time"},model:{value:(_vm.dates.startTime),callback:function ($$v) {_vm.$set(_vm.dates, "startTime", $$v)},expression:"dates.startTime"}}),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#fff"},on:{"click":function($event){return _vm.$refs.menu1.save(_vm.dates)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date Final","persistent-hint":"","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.DateEndFormatted),callback:function ($$v) {_vm.DateEndFormatted=$$v},expression:"DateEndFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","color":"info","min":_vm.dates.start,"max":_vm.nowDate},model:{value:(_vm.dates.end),callback:function ($$v) {_vm.$set(_vm.dates, "end", $$v)},expression:"dates.end"}},[_c('v-text-field',{attrs:{"type":"time"},model:{value:(_vm.dates.endTime),callback:function ($$v) {_vm.$set(_vm.dates, "endTime", $$v)},expression:"dates.endTime"}}),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#fff"},on:{"click":function($event){return _vm.$refs.menu2.save(_vm.dates)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.closeForm,"isValid":function($event){_vm.valido = $event}}},[_vm._v(" Cancelar ")]),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.valido,"loading":_vm.loading},on:{"click":_vm.setInteravalo}},[_vm._v(" Ok ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }