<template>
  <div id="charts">
    <div class="loading" v-if="loading">
      <v-progress-circular
        indeterminate
        color="secondary"
      ></v-progress-circular>
    </div>
      <apexchart v-else ref="demoChart"
        :type="type" height="230" :options="options" :series="series"
      />
  </div>
</template>

<script>
import Vue from "vue";

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

export default {
  props: {
    loading: {
      type:Boolean,
      default: false
    },
    type: {
      type: String,
    },
    options: {
      type: Object,
    },
    series: {
      type: Array,
    }
  },
  data: function() {
    return {
      TensaoOptions: {
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        grid: {
          show: false,
        },
      },
    };
  },

  methods: {
    loadOptions(){

      this.$emit('loadDefaultOptions', this.TensaoOptions )
    },
  },
  mounted () {
    // this.loadOptions()
    // this.$refs.demoChart.updateOptions({
      // plotOptions: {
      //     bar: {
      //       dataLabels: {
      //         position: "top", // top, center, bottom
      //       },
      //     },
      //   },
        // dataLabels: {
        //   enabled: true,
        //   formatter: function (val) {
        //     return val;
        //   },
        //   offsetY: -20,
        //   style: {
        //     fontSize: "12px",
        //     colors: ["#304758"],
        //   },
        // },
        // grid: {
        //   show: false,
        // },
    // })
  },
  computed: {
    theme() {
      return this.$store.state.login.themeDark
    }
  },
  watch: {
    theme(newValue) {
      if(newValue) {
        this.$refs.demoChart.updateOptions({
              xaxis:{
                type: "datetime",
                  min: new Date('19 May 2021').getTime(), //start
          max: new Date('21 May 2021').getTime(), //end
                labels:{
                  style:{
                    colors: "#fff"
                  }
                },
              },
              dataLabels: {
                style: {
                  colors: ["#fff"]
                }
              },
            title: {
              style: {
                color: "#fff"
              }
            },
           tooltip: { theme: "dark"}
           })
      }else {
        this.$refs.demoChart.updateOptions({
          xaxis:{
            type: "datetime",
             //min: new Date('19 May 2021').getTime(), //start
          // max: new Date('21 May 2021').getTime(), //end
            labels:{
              style:{
                colors: "#304758"
              }
            },
          },
          dataLabels: {
            style: {
              colors: ["#304758"]
            }
          },
          title: {
            style: {
              color: ""
            }
          },
          tooltip: { theme: "light"}
          })
      }
    }
  },
};
</script>

<style scoped>
#chart1,
#chart2 {
  width: 100%;
  margin: 35px auto;
}

#chart2 {
  position: relative;
  margin-top: -70px;
  margin-bottom: 0px;
}

#app {
  padding-top: 20px;
  padding-left: 10px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 22px 35px -16px rgba(0, 0, 0, 0.1);
  max-width: 650px;
  margin: 35px auto;
}
.loading {
  min-height: 245px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
