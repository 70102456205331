<template>
<div>
  <div class="ml-5">
  <h3>{{title}}</h3>
  </div>
<div class="gouge">
  <radial-gauge  :value="data" :options="option"></radial-gauge>
  <div class="label"><span>{{value}}</span></div>
</div>
</div>
</template>

<script>
  // import LinearGauge from 'vue2-canvas-gauges/src/LinearGauge'
  import RadialGauge from 'vue2-canvas-gauges/src/RadialGauge'

  export default {
    components: {
      // LinearGauge,
      RadialGauge
    },
    props: {
      value: {
        type: Number,
        default: null
      },
      title: {
        type: String,
        default: ''
      },
      options: { // https://canvas-gauges.com/documentation/user-guide/configuration
        type: Object,
        default: () => ({
          units: '(A)',
          // title: 'title',
          minValue: -80,
          maxValue: 80,
          startAngle: 90,
          ticksAngle: 180,
          renderTo: 'gauge',
          // width: 200,
          // height: 200,
          strokeTicks: true,
          // colorBar: '',
          // colorBarProgress: 'red',
          highlights: [
            {
            'from': -80,
            'to': -40,
            'color': 'red'
            },
            {
            'from': -40,
            'to': -0,
            'color': 'green'
            }
          ],
          minorTicks: 3,
          majorTicks: [
            "-80",
            "-40",
            "0",
            "40",
            "80",
          ],
          colorPlate: '#fff',
          borderShadowWidth: 0,
          borders: false,
          needleType: "arrow",
          needleShadow: true,
          needleWidth: 2,
          needleCircleSize: 7,
          needleCircleOuter: true,
          needleCircleInner: true,
          numberSide: 'left',
          needleSide: 'left',
          animationDuration: 1500,
          animationRule: 'linear',
          // barWidth: 15,
          valueBox: false,
          valueBoxStroke: 2,
          valueTextShadow: false,
          valueDec: 0,
          barBeginCircle: 0,
          fontNumbersSize: 28,
          fontUnitsSize: 30,
          fontValueSize: 30
        })
      }
    },
    computed: {
      data() {
        return this.value
      },
      option(){
        return {
          units: '(V)',
          // title: 'title',
          minValue: -80,
          maxValue: 80,
          startAngle: 90,
          ticksAngle: 180,
          renderTo: 'gauge',
          // width: 200,
          // height: 200,
          strokeTicks: true,
          // colorBar: '',
          // colorBarProgress: 'red',
          highlights: [
            {
            'from': -80,
            'to': -40,
            'color': 'red'
            },
            {
            'from': -40,
            'to': 40,
            'color': 'green'
            },
            {
            'from': 40,
            'to': 80,
            'color': 'red'
            }
          ],
          minorTicks: 3,
          majorTicks: [
            "-80",
            "-40",
            "0",
            "40",
            "80",
          ],
          colorPlate: '#fff',
          borderShadowWidth: 0,
          borders: false,
          needleType: "arrow",
          needleShadow: true,
          needleWidth: 2,
          needleCircleSize: 7,
          needleCircleOuter: true,
          needleCircleInner: true,
          numberSide: 'left',
          needleSide: 'left',
          animationDuration: 1500,
          animationRule: 'linear',
          // barWidth: 15,
          valueBox: false,
          valueBoxStroke: 2,
          valueTextShadow: false,
          valueDec: 0,
          barBeginCircle: 0,
          fontNumbersSize: 28,
          fontUnitsSize: 30,
          fontValueSize: 30
        }
      }
    },
  }
</script>
<style lang="scss" scoped>
.gouge{
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 215px;

  & canvas {
    max-width: 100%!important;
    padding: 15px;
    /* max-height: 70%!important; */
  }

  .label {
    position: absolute;
    top: calc(245px/ 1.222222);
    border: 1px solid #fff;
    width: 4rem;
    text-align: center;
    box-shadow: 1px 1px 3px #808080a3;
    border-radius: 3px;

    & span {
      color: #3a3838a3;
    }
  }
}
</style>
