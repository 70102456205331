<template>
  <v-card class="pt-4" light color="primary">
    <chart height="300" :options="optionsTensao" :series="series" />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import chart from "../charts/chart";
export default {
  name: "CurrentChartCard",
  components: {
    chart,
  },
  data() {
    return {
      // generator: null,

      // optionsTensao: {
      //   chart: {
      //     height: 300,
      //     id: this.id,
      //     animations: {
      //       enabled: false,
      //       dynamicAnimation: {
      //         enabled: false,
      //       },
      //     },
      //   },
      //   dataLabels: {
      //     enabled: false,
      //   },
      //   stroke: {
      //     curve: "straight",
      //   },
      //   title: {
      //     text: this.title,
      //     align: "left",
      //     margin: 20,
      //     style: {
      //       fontSize: "20px",
      //       fontWeight: "bold",
      //     },
      //   },
      //   grid: {
      //     row: {
      //       colors: ["#fff", "transparent"], // takes an array which will be repeated on columns
      //       opacity: 0.5,
      //     },
      //   },
      //   xaxis: {
      //     // type: "datetime",
      //     tickAmount: 6,
      //     // min: 80,
      //     // max: 90,
      //     //min: new Date('19 May 2021').getTime(), //start
      //     // max: new Date('21 May 2021').getTime(), //end
      //     tooltip: {
      //       enabled: false,
      //       datetimeUTC: true,
      //     },
      //     labels: {
      //       format: "dd/MM",
      //     },
      //   },
      //   responsive: [
      //     {
      //       breakpoint: 600,
      //       options: {
      //         yaxis: {
      //           show: false,
      //         },
      //         legend: {
      //           show: false,
      //         },
      //       },
      //     },
      //   ],
      //   yaxis: {
      //     min: () => {return this.configy.min},
      //     max: () => {return this.configy.max }
      //     // max: (max) => {
      //     //   return max;
      //     // },
      //   },
      //   // tooltip: {
      //   //   x: {
      //   //     format: "dd/MM/yy HH:mm:ss",
      //   //   },
      //   // },
      // },
    };
  },
  methods: {},
  props: {
    id: String,
    title: String,
    currentOptions: {
      type: Object,
    },
    series: {
      type: Array,
    },
    tick: {
      type: Number,
    },
    configy: {
      type: [Object, Number],
    },
    currents: {
      type: Array,
      // required: true
    },
  },
  computed: {
    ...mapGetters(["config"]),

    optionsTensao () {
      return {
        chart: {
          height: 300,
          id: this.id,
          animations: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: this.title,
          align: "left",
          margin: 20,
          style: {
            fontSize: "20px",
            fontWeight: "bold",
          },
        },
        Markers:{
          size:0
        },
        grid: {
          row: {
            colors: ["#fff", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          type: "datetime",
          tickAmount:6,
          // min: 80,
          // max: 90,
          //min: new Date('19 May 2021').getTime(), //start
          // max: new Date('21 May 2021').getTime(), //end
          tooltip: {
            enabled: false,
            datetimeUTC: false,
          },
          labels: {
            // format: "dd/MM/yyyy ",
          },
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              yaxis: {
                show: false,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
        yaxis: {

          min: () => {return this.configy.min},
          max: () => {return this.configy.max }
          // max: max => {return max}
        },
        tooltip: {
          x: {
            format: "dd/MM/yyyy HH:mm:ss",
          },
        },
        legend: {
          onItemClick: {
            toggleDataSeries: true
          },
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.mains-current-tab {
  height: 100%;
}
</style>
