<template>
  <v-dialog
    width="620"
    content-class="report-dialog"
    :value="visible"
    @input="$emit('toggle', $event)"
  >
    <template #activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>
    <v-card>
      <v-card-title
        class="headline text--secondary"
        :class="this.$vuetify.theme.dark ? 'primary' : 'secondary'"
      >
      Intervalo
      </v-card-title>
      <v-card-text class="report-text">
        <v-container>
          <v-row>
            <v-col cols="12" lg="6">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="DateStartFormatted"
                    label="Data Inicial"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>

                </template>
                <v-date-picker
                  color="info"
                  v-model="dates.start"
                  no-title
                  :max="nowDate"
                >
                <v-text-field
                  v-model="dates.startTime"
                  type="time"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="#fff"
                  @click="$refs.menu1.save(dates)"
                >
                  OK
                </v-btn>
                </v-date-picker>

              </v-menu>
            </v-col>

            <v-col cols="12" lg="6">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="DateEndFormatted"
                    label="Date Final"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>

                </template>
                <v-date-picker
                  v-model="dates.end"
                  no-title
                  color="info"
                  :min="dates.start"
                  :max="nowDate"
                >
                <v-text-field
                  v-model="dates.endTime"
                  type="time"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="#fff"
                  @click="$refs.menu2.save(dates)"
                >
                  OK
                </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn @click="closeForm" @isValid="valido = $event">
          Cancelar
        </v-btn>
        <v-spacer />
        <v-btn :disabled="!valido" :loading="loading" @click="setInteravalo">
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/pt-br'
import { mapActions } from 'vuex';


export default {
  model: {
    prop: "visible",
    event: "toggle",
  },
  props: {
    closeOn: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valido: false,
      loading: false,
      // visible: false,
      nowDate: new Date().toISOString().slice(0, 10),
      dateStart: null,
      dateEnd: null,
      dates: {
        start: null,
        startTime: '00:00:00',
        end: null,
        endTime: '00:00:00',
      },
      menu1: false,
      menu2: false,
    };
  },

  computed: {
    DateStartFormatted() {
      return this.formatDate(this.dates.start);
    },
    DateEndFormatted() {
      return this.formatDate(this.dates.end);
    },
    siteId() {
      return parseInt(this.$route.params.siteId, 10);
    },
  },

  watch: {
    "dates.end"(value) {
      if (value !== null) {
        this.valido = true;
      } else {
        this.valido = false;
      }
    },
    closeOn(value) {
      if (value) {
        this.dates = {};
      }
    },
  },
  mounted () {
    dayjs.extend(duration)
  },

  methods: {
    ...mapActions(['loadlinechart']),
    async setInteravalo() {
      this.loading = true
      dayjs.extend(utc)
      dayjs.extend(timezone)
      const parametro = {
        start: dayjs(`${this.dates.start} ${this.dates.startTime}`).tz("America/Sao_Paulo", true).unix(),
        end: dayjs(`${this.dates.end} ${this.dates.endTime}`).tz("America/Sao_Paulo", true).unix(),
        id: this.siteId
      }
      try {
        this.$store.commit('Intervalo', parametro)
        await this.loadlinechart(parametro)
        this.$emit('activelineChart', parametro)
        this.loading = false
        this.$emit("toggle", false);
      } catch (error) {
        console.log(error)
        this.loading = false
      }
        // this.loading = false


    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    closeForm() {
      this.dates = {};
      this.$emit("toggle", false);
    },
  },
};
</script>

<style lang="scss">
/* @import "~vue2-daterange-picker/dist/vue2-daterange-picker.css"; */
@import "@/styles/mixins";

.report-dialog {
  position: absolute;
  top: 0;
}

.report-text {
  margin-top: 32px;

  @include sm-and-up {
    .sm-and-up-mr-4 {
      margin-right: 16px !important;
    }
  }
}

.report-datepicker {
  &.vue-daterange-picker {
    width: 100%;

    .daterangepicker {
      position: fixed;
    }
  }
  select {
    -moz-appearance: auto;
    -webkit-appearance: auto;
  }
}
</style>
